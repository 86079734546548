<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/AIAlogo.png" />
    </div>
    <router-view></router-view>
    <div class="footer">
      如有查詢，請聯絡營業傳訊及活動部 (電郵：aiahk.agencycommunicationevents@aia.com)
    </div>
  </div>
</template>

<script>
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import "@/assets/main.css";
</style>
