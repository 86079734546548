<template>
  <div class="route-item">
    <div class="banner-wrapper">
      <img src="@/assets/p1_text.png" />
    </div>
    <div class="login-wrapper">
      <img src="@/assets/agentno.png" />
      <input class="username-input" type="text" v-model="agentcode" />
      <div class="submit-btn-wrapper" @click="login">
        <img src="@/assets/login.png" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      agentcode: null,
    };
  },
  methods: {
    async login() {
      if (this.agentcode == null || this.agentcode == "") {
        alert("Please enter agent code to login");
        return;
      }
      try {
        let formData = new FormData();
        formData.append("agentCode", this.agentcode);
        let result = await axios.post("/api/login.php", formData);
        if (result && result.data && result.data.agentCode) {
          localStorage.setItem("agentCode", result.data.agentCode);
          localStorage.setItem("agentName", result.data.agentName);
          let count = result.data.uploadCount;
          if (parseInt(count) > 0) {
            console.log(`Login, user upload count: ${count}`);
            // Router push to uploaded alert page
            this.$router.push("warning");
          } else {
            console.log(`Login OK`);
            this.$router.push("upload");
          }
        } else {
          alert("Invalid agent code");
        }
      } catch (error) {
        console.error(error.message);
        alert("Login fail, please try again");
      }
    },
  },
};
</script>
<style scoped>
.login-wrapper {
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 8%;
}
.banner-wrapper img {
  width: 100%;
  object-fit: contain;
}
.login-wrapper img {
  width: 84%;
  object-fit: contain;
}
.username-input {
  width: 100%;
  margin-top: 5%;
  padding-bottom: 3px;
  border: none; /* Remove the default border */
  border-bottom: 3px solid black; /* Add a bottom line */
  outline: none; /* Remove the default focus outline */
  background: none;
  font-size: 20px;
  padding: 0;
}
.submit-btn-wrapper {
  margin-top: 8%;
  width: 45.7%;
  cursor: pointer;
}
</style>