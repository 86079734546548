import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from './components/Login.vue'
import UploadPage from './components/Upload.vue'
import CheckoutPage from './components/Checkout.vue'
import WarningPage from './components/Warning.vue'
const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/upload',
    name: 'UploadPage',
    component: UploadPage
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: CheckoutPage
  },
  {
    path: '/warning',
    name: 'WarningPage',
    component: WarningPage
  },
  // Add more routes as needed
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router