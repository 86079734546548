<template>
  <div class="route-item">
    <div class="wrapper">
      <div class="text-wrapper">
        <img src="@/assets/p3text1.png" />
      </div>
      <div class="img-wrapper">
        <img id="icon" src="@/assets/p3icon.png" />
        <img id="text" src="@/assets/p3text2.png" />
      </div>
      <div class="logout-icon-wrapper">
        <img src="@/assets/logout.png" @click="logout"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckoutPage",
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    logout() {
        localStorage.clear();
        this.$router.push("/")
    }
  }
};
</script>
<style scoped>
.wrapper,
.img-wrapper {
  position: relative;
  text-align: center;
}
.img-wrapper {
    aspect-ratio: 0.965;
}
.text-wrapper {
  width: 100%;
  text-align: center;
}
.text-wrapper img {
  width: 51%;
  object-fit: contain;
}
#icon {
  width: 55%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}
#text {
  width: 92.5%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top:56px;
}
.img-wrapper img {
  object-fit: contain;
}
.logout-icon-wrapper img{
    margin-top:16px;
    width: 33.3%;
    object-fit: contain;
}
</style>