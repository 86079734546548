<template>
  <div class="route-item">
    <div class="title-text">Hello {{ agentName }}</div>
    <div class="image-wrapper">
      <img src="@/assets/p2text.png" />
    </div>
    <div class="upload-image-wrapper">
      <img id="upload-btn" src="@/assets/upload.png" @click="openFileInput" />
      <img id="p2-text-2" src="@/assets/p2text2.png" />
      <input
        type="file"
        v-show="false"
        ref="fileInput"
        accept="audio/*,video/*"
        @change="handleFileUpload"
      />
    </div>
    <div class="loader" v-if="loading == true">
      <img src="@/assets/loader.gif" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UploadPage",
  data() {
    return {
      agentCode: null,
      agentName: null,
      videoFile: null,
      loading: false,
    };
  },
  methods: {
    openFileInput() {
      // Click the file input element
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      // Check if a file has been uploaded
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const fileType = file.type.split("/")[0];
        const fileSizeLimit = 50 * 1024 * 1024; // 20MB in bytes

        // Check if the uploaded file is a video or audio file
        if (fileType === "audio" || fileType === "video") {
          // Check if the file duration is within the specified limit (21 seconds)
          const durationLimit = 21; // in seconds
          const video = document.createElement("video");
          video.src = URL.createObjectURL(file);
          video.onloadedmetadata = () => {
            if (video.duration < durationLimit) {
              // Check if the file size is within the specified limit
              if (file.size <= fileSizeLimit) {
                // Handle the file upload here
                console.log(`File name: ${file.name}`);
                this.videoFile = file;
                this.uploadFile();
              } else {
                alert(
                  "影片上限為20秒及少於50mb，請重新拍攝或選擇。"
                );
                console.log(`File size exceeds the limit of 20MB.`);
              }
            } else {
              alert(
                "影片上限為20秒及少於50mb，請重新拍攝或選擇。"
              );
              console.log(
                `File duration exceeds the limit of ${durationLimit} seconds.`
              );
            }
            URL.revokeObjectURL(video.src);
          };
        } else {
          console.log(
            "Invalid file type. Please upload a video or audio file."
          );
        }
      }
    },
    async uploadFile() {
      try {
        let formData = new FormData();
        this.loading = true;
        formData.append("file", this.videoFile);
        formData.append("agentCode", this.agentCode);
        await axios.post("/api/upload.php", formData).finally(() => {
          this.loading = false;
        });
        this.$router.push("/checkout");
      } catch (error) {
        alert("Error while uploading, please try again later");
        this.loading = false;
      }
    },
  },
  mounted() {
    // Check if the "agentCode" item exists in local storage and is not an empty string
    const agentCode = localStorage.getItem("agentCode");
    const agentName = localStorage.getItem("agentName");
    if (agentCode && agentCode.trim() !== "") {
      this.agentCode = agentCode;
      this.agentName = agentName;
      // Do something with the "agentCode" value
      window.scrollTo(0, 0);
      console.log(`Agent code: ${agentCode}`);
    } else {
      // Handle the case where the "agentCode" value is missing or empty
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.title-text {
  font-size: 20px;
  text-align: center;
  font-weight: medium;
}
.image-wrapper {
  padding-left: 6.5%;
  padding-right: 6.5%;
  margin-top: 4px;
}
.image-wrapper img {
  width: 100%;
  object-fit: contain;
}
.upload-image-wrapper {
  margin-top: 10%;
  position: relative;
  aspect-ratio: 1.4;
  text-align: center;
}
#upload-btn {
  left: 0;
  right: 0;
  margin: auto;
  width: 60%;
  position: absolute;
  z-index: 2;
  object-fit: contain;
  cursor: pointer;
}
#p2-text-2 {
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  z-index: 1;
  width: 100%;
  top: 10%;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.loader img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  width: 30%;
  margin: auto;
  opacity: 0.65;
}
</style>